import StatisticView from '@/views/StatisticView';
import { createRouter, createWebHistory } from 'vue-router'
import ProfilesView from '@/views/ProfilesView'
import ProfileView from '@/views/ProfileView';

const routes = [
  {
    path    : '/',
    name    : 'home',
    redirect: {name: 'list', params: {'filter': 'finish'}}
  },
  {
    path     : '/list/:filter',
    name     : 'list',
    component: ProfilesView,
    params   : true
  },
  {
    path     : '/profile/:id',
    name     : 'profile',
    component: ProfileView
  },
  {
    path     : '/stat',
    name     : 'statistic',
    component: StatisticView
  }
]

const router = createRouter({
                              history: createWebHistory(process.env.BASE_URL),
                              routes
                            })

export default router
