<template>
  <nav class="bottom-navigation">
    <div class="nav-btn">
      <a href="javascript:void(0)" @click="back">
        <BackIcon/>
      </a>
    </div>
    <div class="nav-btn">
      <router-link :to="{name:'list',params:{'filter': 'finish'}}">
        <span>{{ total }}</span>
      </router-link>
    </div>
    <div class="nav-btn">
      <router-link :to="{name:'statistic'}">
        <span>
          <ProfileIcon/>
        </span>
      </router-link>
    </div>
  </nav>
</template>

<script>
import BackIcon from '@/components/Icon/BackIcon';
import ProfileIcon from '@/components/Icon/ProfileIcon';

export default {
  name      : 'NavbarComponent',
  components: {ProfileIcon, BackIcon},
  computed  : {
    account() {return this.$store.getters.getAccount},
    total() {return this.$store.getters.getTotal}
  },
  methods   : {
    back() {
      if (window.history.state.back) {
        this.$router.back()
      } else if (window.Telegram.WebApp) {
        window.Telegram.WebApp.close()
      }
    }
  }
}
</script>

<style>
.bottom-navigation {
  position        : fixed;
  left            : 0;
  bottom          : 0;
  display         : flex;
  flex-direction  : row;
  justify-content : space-around;
  align-items     : center;
  width           : 100%;
  background      : #212121;
  box-shadow      : 0 0 5px #212121;
  border-top      : 1px solid #2f2f2f;
}

.bottom-navigation svg {
  fill   : white;
  color  : white;
  margin : -4px 0;
}

.bottom-navigation .nav-btn {
  width      : 100%;
  text-align : center;
}

.bottom-navigation .nav-btn a {
  text-decoration : none;
}

.bottom-navigation .nav-btn span {
  padding       : 8px 12px;
  line-height   : 30px;
  background    : none;
  border-radius : 50px;
  font-size     : 18px;
  font-weight   : bold;
}

.bottom-navigation .nav-btn .router-link-active span {
  background : linear-gradient(to top right, #fd267a, #ff6036);
}

.bottom-navigation a {
  width   : 100%;
  padding : 10px 15px;
  display : block;
}

</style>