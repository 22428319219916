import { createStore } from 'vuex';

export default createStore(
  {
    state    : {
      account: undefined,
      total: 0
    },
    getters  : {
      getAccount: state => state.account,
      getTotal: state => state.total
    },
    mutations: {
      setAccount: (state, payload) => state.account = payload,
      setTotal: (state, payload) => state.total = payload
    }
  });
