<template>
  <div class="container">
    <div class="row">
      <div class="stat-block">
        <div class="stat">
          <div class="label">Собрано контактов</div>
          <div class="value">{{ this.total }}</div>
        </div>
        <div class="stat">
          <div class="label">Всего отправлено</div>
          <div class="value">{{ this.messages }}</div>
        </div>
      </div>
    </div>
    <div class="row controls">
      <label>
        Matches
        <input type="checkbox" v-model="autoMatches" @change="needChanges = true">
      </label>

      <label>
        Отправлять сообщения
        <input type="checkbox" v-model="autoMessage" @change="needChanges = true">
      </label>
    </div>
    <div class="row controls">
      <label>
        С какого числа начинать собирать matches
        <input type="date" v-model="start_at" @change="needChanges = true">
      </label>
    </div>

    <div class="row vars">
      <ProfileInput v-model="vars.my_location" @input="needChanges = true" :label="'Текущее местоположение'"/>
      <ProfileInput v-model="vars.my_region" @input="needChanges = true" :label="'Текущий регион'"/>
      <ProfileInput v-model="vars.time_in_region" @input="needChanges = true" :label="'Время в этом регионе'"/>
      <ProfileInput v-model="vars.next_region" @input="needChanges = true" :label="'Следующий регион'"/>
      <ProfileInput v-model="vars.globaltask" @input="needChanges = true" :label="'Глобальная задача'"/>
      <ProfileInput v-model="vars.acc" @input="needChanges = true" :label="'Анкета'"/>
    </div>
  </div>
  <teleport to="#app">
    <div class="save-button" v-if="needChanges" @click="save">
      Сохранить изменения
    </div>
  </teleport>
</template>

<script>
import ProfileInput from '@/views/ProfileInput';

export default {
  name      : 'StatisticView',
  components: {ProfileInput},
  data() {
    return {
      load       : false,
      vars       : {},
      autoMatches: false,
      autoMessage: false,
      needChanges: false,
      start_at   : new Date(),
      total      : 0,
      messages   : 0
    }
  },
  mounted() {
    if (this.account) {
      this.setVars(this.account)
    }
    this.getStatistic()
  },
  computed: {
    account() {return this.$store.getters.getAccount}
  },
  methods : {
    setVars(account) {
      if (account.vars) {
        this.vars = account.vars
      }
      this.autoMatches = !!account.matches
      this.autoMessage = !!account.messages
      this.start_at    = account.start_at ? new Date(account.start_at * 1000).toISOString().slice(
          0,
          10
      ) : new Date(account.created_at * 1000).toISOString().slice(0, 10)
      this.needChanges = false
    },
    getStatistic() {
      return fetch('/api/account/stat', {
        method : 'GET',
        headers: {'x-init': window.initData}
      }).then(data => data.json()).then(json => {
        if (json.error === undefined) {
          this.total    = json.total
          this.messages = json.messages
        }
        console.log(json)
      });
    },
    save() {
      if (this.load) {
        return false
      }
      this.load = true
      return fetch('/api/account/update', {
        method : 'POST',
        body   : JSON.stringify({
                                  vars    : this.vars,
                                  messages: this.autoMessage,
                                  matches : this.autoMatches,
                                  start_at: (new Date(this.start_at).getTime() / 1000) | 0
                                }),
        headers: {'x-init': window.initData}
      }).then(data => data.json()).then(json => {
        this.needChanges = false
        this.load        = false
        if (json.error === undefined) {
          this.$store.commit('setAccount', json)
        }
        console.log(json)
      });
    }
  },
  watch   : {
    account(newVal) {
      this.setVars(newVal)
    }
  }
}
</script>

<style>
.save-button {
  position   : fixed;
  width      : 100%;
  bottom     : 0;
  left       : 0;
  background : linear-gradient(to top right, #fd267a, #ff6036);
  text-align : center;
  font-size  : 24px;
  padding    : 10px;
  cursor     : pointer;
}

.vars > div {
  margin-top : 24px;
}

.vars {
  margin-bottom : 30vh;
}

.vars label {
  font-size : 24px;
}

.stat-block {
  display         : flex;
  justify-content : center;
  padding         : 25px 0;
  margin-bottom   : 40px;
  gap             : 10px;
}

.stat {
  width         : 50%;
  text-align    : center;
  font-size     : 18px;
  line-height   : 24px;
  background    : #5895c6;
  border-radius : 15px;
  padding       : 10px 0;
}

.stat .label {
  font-weight : lighter;
}

.stat .value {
  font-weight : bold;
}

.controls {
  padding         : 10px 0 40px;
  font-size       : 24px;
  display         : flex;
  justify-content : space-around;

}
</style>
