<template>
    <PreloaderComponent v-if="loading"/>
    <router-view v-else/>
    <NavbarComponent/>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent';
import PreloaderComponent from '@/components/PreloaderComponent';

export default {
  name      : 'App',
  components: {
    NavbarComponent,
    PreloaderComponent
  },
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    window.initData = localStorage.getItem('initData')

    if (window.Telegram) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.enableClosingConfirmation();
      window.Telegram.WebApp.disableVerticalSwipes();
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        window.document.addEventListener('touchmove', function (e) {
          if (e.scale !== 1) {
            e.preventDefault();
          }
        }, {passive: false});
        window.Telegram.WebApp.onEvent('viewportChanged', window.Telegram.WebApp.expand)
      }
      if (window.Telegram.WebApp.initData && window.Telegram.WebApp.initData.length) {
        localStorage.setItem('initData', window.Telegram.WebApp.initData);
        window.initData = window.Telegram.WebApp.initData
      }
    }

    return fetch('/api/account', {
      method : 'GET',
      headers: {'x-init': window.initData}
    }).then(data => data.json()).then(json => {
      if (json.error === undefined) {
        this.$store.commit('setAccount', json.account)
        this.$store.commit('setTotal', json.total)
      }
      console.log(json)
    });

    // Симуляция задержки для прелоадера
    // setTimeout(() => {
    //   this.loading = false;
    // }, 2000);
  }
}
</script>

<style>
:root {
  color-scheme : dark;
}

* {
  box-sizing : border-box;
  margin     : 0;
  padding    : 0;
  color      : white;
  outline: none;
}

#app {
  font-family : ui-sans-serif, -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica, Apple Color Emoji, Arial, Segoe UI Emoji, Segoe UI Symbol;
  min-height      : 100vh;
  background  : #212121;
  padding-bottom: 60px;
}

.container {
  padding   : 15px 10px;
  margin    : 0 auto;
  max-width : 768px;
}

.row {
  margin : -15px 0;
}
</style>
